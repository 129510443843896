import { ThemeProvider } from 'next-themes'

import '../styles/globals.css'

// Use these global styles for formatting code blocks
import '../styles/code/atom-one-light.css'
import '../styles/code/atom-one-dark.css'

export default function App({ Component, pageProps }) {
  return (
    <ThemeProvider enableSystem={true} attribute="class">
      <Component {...pageProps} />
    </ThemeProvider>
  )
}
